import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent implements OnInit {

  constructor(public oktaAuth: OktaAuthService) { }

  ngOnInit(): void {
  }

  sessionKill() {
    this.oktaAuth.revokeAccessToken(); // strongly recommended
    this.oktaAuth.closeSession()
      .then(() => {
        console.log("CLOSE SESSION");

      })

    Swal.fire({
      icon: 'info',
      html: '<h5>Your session has expired.</h5>' +
        'Click OK to return to the Login page.',
      confirmButtonText: 'OK',
      iconColor: '#11375f'
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/login';
        // localStorage.clear();
      }
    })
  }
}
