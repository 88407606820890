import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  childModal: Boolean | undefined;
  // baseURL: string = "https://uu8brdh8fa.execute-api.us-east-1.amazonaws.com/TgsGlobalDevelopment/DataLake/Search";
  constructor(public http: HttpClient, private oktaAuth: OktaAuthService) {

  }
  getPostdata(getApiurl: string) {
    return this.http.get(getApiurl)
  }
  commonPost(baseURL: string, body: any): Observable<any> {

    const reqHeaders = this.getHeaders();
    return this.http.post(baseURL, body, { headers: reqHeaders });
  }
  private getHeaders(headers?: any): HttpHeaders {
    const token = this.getToken();



    const finalHeaders = Object.assign({}, headers,

      {
        'Authorization': 'Bearer ' + token
      },
      { 'Accept': '*/*' },
      { 'x-api-key': environment.apiKey }
    );
    return new HttpHeaders(finalHeaders);

  }
  getToken() {
    const accessToken = this.oktaAuth.getAccessToken();


    return accessToken;


  }
}


