<div id="cover-spin" *ngIf="isLoaded"></div>
<div bsModal #lgModalChild="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-sizes-name1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title" style="color: #FFA500;">Export Options</h4>
            </div>
            <div class="modal-body">

                <div class="row ">

                    <div class="col-md-6">
                        <div class="form-check-inline col-12">
                            <label class="padText">Name <span style="color:#ff0000; margin-left: 3px;">*</span></label>
                            <input type="text" class="form-control " name="name" [(ngModel)]="exportName"
                                [maxlength]="maxCharsName">
                        </div>
                        <br />

                        <div class="form-check-inline col-12">
                            <label class="padTextDesc">Description </label>
                            <textarea class="form-control " [(ngModel)]="exportDesc" [maxlength]="maxChars" rows="5"
                                cols="40"></textarea>

                        </div>
                        <h6 class="padLeftFormat"><b>File Format <span style="color:#ff0000">*</span></b>
                            <div class="toolTip">
                                <i class="fa fa-info-circle" placement="right" [ngbTooltip]="tooltipHtml"
                                    tooltipClass="tooltip-custom"></i>
                            </div>
                            <!--ToolTip-->
                            <ng-template #tooltipHtml>
                                <div [innerHtml]="contentForFileFormat"></div>
                            </ng-template>
                        </h6>
                        <accordion style="margin-top:5px;">
                            <accordion-group *ngFor="let option of options ; let i = index" #groupval
                                style="margin-top:5px;" [isOpen]="option.isAccordionOpen">

                                <div accordion-heading style="width:100%;" [ngStyle]="option.id">
                                    <i class="fa fa-s"
                                        [ngClass]="{'fa-angle-down': groupval?.isOpen, 'fa-angle-right': !groupval?.isOpen}"></i>
                                    {{option.label}}

                                </div>
                                <div *ngIf="option.value == 1">
                                    <div *ngFor="let item of selected297;let i= index">
                                        <label class="padCls">

                                            <input type="checkbox" name="format1" [checked]="item.selected"
                                                [disabled]="item.disable" (change)="is297Selected(item,i)">
                                            <label class="{{item.label}}" style="padding-left:10px">
                                                {{item.label}}
                                            </label>

                                        </label>
                                    </div>
                                    <div *ngIf="isCheck297">
                                        <h6 class="padCls">Directional Survey North Reference</h6>
                                        <div *ngFor="let val of northReference;let i = index" class="clsDir"
                                            style="float:left">
                                            <div class=" radio">
                                                <input type="radio" name="northRefer" id="radio-{{val.id}}"
                                                    [checked]="val.checked" value="{{val.value}}"
                                                    (change)="isNorthRefCheck(val)" />
                                                <label for=" radio-{{val.id}}" style="padding-left:10px">
                                                    {{val.label}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="option.value == 2">
                                    <div *ngFor="let item of selected298;let i= index">
                                        <label class=" padCls">
                                            <input type="checkbox" [checked]="item.selected" [disabled]="item.disable"
                                                (change)="is298Selected(item,i)">
                                            <label class="{{item.label}}" style="padding-left:10px">
                                                {{item.label}}
                                            </label>

                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="option.value == 3">
                                    <label class=" padCls block">
                                        <input type="checkbox" name="checkboxes" class="containerBlock"
                                            (change)="toggleCheckboxAll($event)" [checked]="checkedAll">
                                        <label style="padding-left:10px">All</label>
                                    </label>
                                    <br />
                                    <label class="padCls ">
                                        <input type="checkbox" name="checkboxes" (change)="headerCheck($event)"
                                            [checked]="checkedHeader" [disabled]="checkedHeader">
                                        <label style="padding-left:10px" [ngStyle]="styleHeader">Headers</label>
                                    </label>
                                    <div *ngFor="let checkbox of csvSelected;let i= index">

                                        <label class=" padCls">
                                            <input type="checkbox" name="trades" [checked]="checkbox.selected"
                                                [disabled]="checkbox.disable" (change)="isCSVSelected(checkbox, i)">
                                            <label style="padding-left:10px" ng-style="checkbox.keyName">
                                                {{checkbox.label}}
                                            </label>
                                        </label>
                                    </div>
                                </div>
                            </accordion-group>
                        </accordion>

                    </div>
                    <div class="col-md-6">

                        <h6><b>Type <span style="color:#ff0000">*</span></b></h6>
                        <div *ngFor="let option of newOptions;let i = index">
                            <div class="radio">
                                <input type="radio" name="fullExpoRadio" id="radio-{{option.id}}"
                                    [checked]="option.checked" value="{{option.value}}" (change)="isTypeChange(option)"
                                    [disabled]="option.disable" />
                                <label for=" radio-{{option.id}}" style="padding-left:10px"> {{option.label}}
                                </label>

                                <div *ngIf=" option.value == 4" class="toolTip">
                                    <i class="fa fa-info-circle" placement="top" [ngbTooltip]="tooltipHtml"
                                        tooltipClass="tooltip-custom"></i>
                                </div>
                                <!--ToolTip-->
                                <ng-template #tooltipHtml>
                                    <div [innerHtml]="contentForFullExport"></div>
                                </ng-template>
                                <div *ngIf=" option.value == 5" class="toolTip">
                                    <i class="fa fa-info-circle" placement="top" [ngbTooltip]="tooltipHtmll"
                                        tooltipClass="tooltip-custom"></i>
                                </div>
                                <ng-template #tooltipHtmll>
                                    <div [innerHtml]="contentForDate"></div>
                                </ng-template>
                                <span *ngIf="option.value == 5 && isFullExportOption">
                                    &nbsp;&nbsp;
                                    <input checked="isFirstFullCheck" type="checkbox" name="fullExpo" id="firstRun"
                                        [(ngModel)]="isFirstFullCheck" [disabled]="checkDisabled"
                                        (change)="isFirstRunSelected($event)" />
                                    <label for="firstRun" style="padding-left:4px"> First-run Full Export
                                    </label></span>
                            </div>

                        </div>
                        <div class="col-md-8 mt-3" *ngIf="!showScheduledate || !isFirstFullCheck">

                            <input placeholder="MM/DD/YYYY" class="form-control" bsDatepicker
                                [disabled]="isdisabled || isDisableType" (bsValueChange)="onDateChange($event);"
                                [(ngModel)]="selectedDate" id="dateinput" #removeDisabled
                                (change)="isInvalidDate($event)" (focus)="resetOnDate()" #dp="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' ,returnFocusToInput: false }">

                            <span class="input-group-addon"><i (click)="dp.toggle()"
                                    class="date-picker-icon fa fa-calendar"
                                    [ngClass]="isdisabled || isDisableType ? 'fa-calendar-disable':'fa-calendar'"></i></span>

                        </div>

                        <div *ngIf="isShowSchedule " class="padTop2">
                            <h6 class="display"><b>Schedule <span style="color:#ff0000">*</span></b></h6>
                            <div class="toolTip">
                                <i class="fa fa-info-circle" placement="top" [ngbTooltip]="tooltipHtml"
                                    tooltipClass="tooltip-custom"></i>
                            </div>
                            <ng-template #tooltipHtml>
                                <div [innerHtml]="contentForSchedule"></div>
                            </ng-template>
                            <!-- ui-switch -->
                            <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" color="black"
                                class="m-2 toggle-s" (change)="onChange($event)">
                            </ui-switch>
                        </div>

                        <div *ngIf="isShowScheduleSelection ">
                            <div class="alignP pb-3">
                                <h6 class="padRight">Start Date <span style="color:#ff0000">*</span></h6>
                                <input placeholder="MM/DD/YYYY" class="form-control frmCtrl" bsDatepicker
                                    #dps="bsDatepicker" [minDate]="minStartDate" id="startDate"
                                    (bsValueChange)="onStartDateChange($event)" (change)="isInvalidStartDate($event)"
                                    (focus)="resetOnStartDate()" [(ngModel)]="selectedStartDate"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY', returnFocusToInput: false }">

                                <span class="input-group-addon"><i (click)="dps.toggle()"
                                        class="start-date-picker-icon fa fa-calendar"></i></span>
                            </div>
                            <div class="alignP pb-3">
                                <h6 class="centerText">Repeat Every&nbsp;<span style="color:#ff0000">*</span></h6>
                                <div class="padLeftFormat col-2">
                                    <input [min]="minValue" (keypress)="numberOnly($event)" [(ngModel)]="InputValue"
                                        (keyup)="isrepeat($event);" [max]="maxValue" minMax
                                        class="form-control text-center marLeftStDt" type="text" />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style="margin: auto;">Week(s)</span>
                            </div>

                            <div class="alignP pb-3">
                                <h6 class=" col-5">On day of week&nbsp;<span style="color:#ff0000">*</span></h6>

                                <div *ngFor="let day of week_day; let i = index">
                                    <input type="reset" class="circle" placeholder="" [(ngModel)]="day.value"
                                        (click)="disableOther(day,i)"
                                        [ngClass]="(day.isSelected)? 'active':'not-active'" />
                                </div>

                            </div>
                            <div class="alignP pb-3">
                                <h6 class="padRight">End Date <span style="color:#ff0000">*</span></h6>
                                <input placeholder="MM/DD/YYYY" class="form-control frmCtrlEnd" bsDatepicker
                                    #dpe="bsDatepicker" placement="top" [minDate]="minEndDate" [maxDate]="maxEndDate"
                                    (bsValueChange)="onEndDateChange($event)" [(ngModel)]="selectedEndDate"
                                    (change)="isInvalidEndDate($event)" (focus)="resetOnEndDate()"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY', returnFocusToInput: false }">

                                <span class="input-group-addon"><i (click)="dpe.toggle()"
                                        class="start-date-picker-icon fa fa-calendar"></i></span>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class=" modal-footer">
                <button type="button" class="btn btn-dark cus-btn" (click)="export()"
                    [disabled]="!(isShow297 || isShow298 || isShowCSV ) || (isCheckedInc && !checkDate)  || !exportName  || !(isNanSt && isNanEnd && isNanRepeat && isNanoccur)">Export</button>
                <button type="button" class="btn btn-danger" (click)="refresh();lgModalChild.hide();">Cancel</button>

            </div>
        </div>
    </div>
</div>