import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.css']
})
export class ProtectedComponent implements OnInit {
  message;
  isAuthenticated = false;
  showPage: boolean= true;
  constructor(public oktaAuth: OktaAuthService) {
    this.message = 'Protected endpoint!';
  }

  async ngOnInit(): Promise<void> {
    // get authentication state for immediate use
    const isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (isAuthenticated) {
     this.showPage=true
    }
  }

}
