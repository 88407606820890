import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import Swal from 'sweetalert2';
import { GlobalConstants } from '../app.constants';
import { ExportComponent } from '../export/export.component';
import { AuthorizationComponent } from '../authorization/authorization.component'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',

  styleUrls: ['./home.component.css'],
  providers: [NgbModalConfig, NgbModal]

})
export class HomeComponent implements OnInit {
  testFromParent: any[] = [];
  @ViewChild("tref") tref!: ExportComponent;
  higlightState: any;
  higlightCounty: any
  state: any[] = [];
  county: any[] = [];
  clearSelectdStates: any[] = [];
  selectedState: any[] = [];
  selectedCounty: any[] = [];
  getCountyList: any[] = [];
  countOfState: any;
  aggreationList: any;
  dropdownList: any[] = [];
  selectedCounties: any[] = [];
  dropdownSettings = {};
  isLoaded: boolean = false;
  WellProductionCount = 0;
  WellCount = 0;
  WellActiveProducingCount = 0;
  SlantSideTrackCount = 0;
  SlantDirectionalCount = 0;
  SlantVerticalCount = 0;
  SlantHorizontalCount = 0;
  selectedStateCount = 0;
  getCountyCount: any;
  unkowncount = 0;
  getResponse: any;
  getCounty: any[] = [];
  coutyCount: any;
  getStatevalue: any[] = [];
  aggreationstatevalue: any;
  coutyCounts = 0;

  removeDup: any[] = [];
  collectCount: any[] = [];
  getstatesToQuery: any[] = [];
  sessionKillVar: any;
  selectedCountyListCount: any;
  totalStateCount: any;
  getcountiesToQUery: any[] = [];
  getSameCountList: any[] = [];
  getSameStatefromparent: any[] = [];
  assignValue: any;
  stateWellCount = 0;
  stateWellProducionCount = 0;
  stateSlantSideTrackCount = 0;
  stateSlantDirectionalCount = 0;
  stateSlantVerticalCount = 0;
  stateWellActiveProducingCount = 0;
  stateSlantHorizontalCount = 0;
  stateUnknownCount = 0;
  constructor(private dataService: DataService, private spinner: NgxSpinnerService,
    public http: HttpClient, public activeModal: NgbActiveModal,
    private modalService: NgbModal, public oktaAuth: OktaAuthService,
    public router: Router) {

  }

  ngOnInit(): void {

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.dropdownList = [];
    this.selectedCounties = [];
    this.getStatesAndCount();
    this.getCountyAndCount();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'StateCounty',
      textField: 'StateCounty',
      selectAllText: '',
      unSelectAllText: '',
      itemsShowLimit: 10,
      allowSearchFilter: false
    };
    let alertComp = new AuthorizationComponent(this.oktaAuth);
    let currentTime = new Date();
    let expirationTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), GlobalConstants.setHrs, GlobalConstants.setMins, GlobalConstants.setSecs, 0);
    let millisTillExpiration = expirationTime.getTime() - currentTime.getTime();
    setTimeout(() => { alertComp.sessionKill() }, millisTillExpiration);

    this.oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
      if (key == 'accessToken') {
        alertComp.sessionKill();
      } else {
      }
    });

  }

  onCounty_select(item: any) {
    if (this.selectedState.length == 0 && this.selectedCounties.length == 0) {
      this.getStatesAndCount();
      this.getCountyAndCount();
    }
    this.calculateCounts(this.selectedState, this.selectedCounties);

    this.getCountyCount = this.selectedCounty.length;
    this.selectedCounty = item;

  }

  onSelect_state(sel_id: any) {
    for (let l = 0; l < sel_id.length; l++) {
      if (this.clearSelectdStates.includes(sel_id[l].Key) === false) this.clearSelectdStates.push(sel_id[l].Key);
    }
    if (this.selectedState.length == 0 && this.selectedCounties.length == 0) {
      this.getStatesAndCount();
      this.getCountyAndCount();
    }
    this.calculateCounts(this.selectedState, this.selectedCounties);

    this.getCountyList = this.county.filter((item: any) => sel_id.some((re: any) => item.State == re.Key));
    if (sel_id.length === 0) {

      this.getCountyList = this.county;
      this.dropdownList = this.getCountyList;
    }
    this.dropdownList = this.getCountyList;
    this.coutyCount = this.dropdownList.length;
  }

  clearSelectdCountyValue(state_code: any) {
    this.clearSelectdStates = this.clearSelectdStates.filter((item: any) => item != state_code.Key);


    this.selectedCounties = this.selectedCounties.filter((item: any) => item.State != state_code.Key);
    this.calculateCounts(this.selectedState, this.selectedCounties);
    if (this.selectedState.length == 0 && this.selectedCounties.length == 0) {
      this.getStatesAndCount();
      this.getCountyAndCount();
    }
  }

  clearStates() {
    let clearedCounties = this.selectedCounties.filter((item: any) => !this.clearSelectdStates.some((re: any) => item.State == re));

    this.selectedCounties = clearedCounties;
    if (this.selectedCounties.length > 0) {
      this.calculateCounts(this.selectedState, this.selectedCounties);
    } else {
      this.clearArray();
    }
  }

  clearArray() {
    this.selectedState = [];
    this.getCountyList = [];
    this.selectedCounty = [];
    this.clearSelectdStates = [];
    this.selectedCounties = [];
    this.getStatesAndCount();
    this.getCountyAndCount();
    this.WellCount = 0;
    this.WellActiveProducingCount = 0;
    this.SlantSideTrackCount = 0;
    this.SlantDirectionalCount = 0;
    this.SlantVerticalCount = 0;
    this.SlantHorizontalCount = 0;
    this.WellProductionCount = 0;
    this.coutyCount = this.county.length;
  }

  getStatesAndCount() {
    this.isLoaded = true;

    let apiUrl = environment.apiurl;
    let ipBody = {

      "SearchType": "WpdProductCounts",
      "SearchFieldValues": [
        {
          "Field": "WellActiveProducingCount",
          "NotEqualOperator": false,
          "Value": 0
        },
        {
          "Field": "Country",
          "NotEqualOperator": false,
          "Value": "UNITED STATES OF AMERICA"
        }
      ],
      "Aggregations": [
        {
          "AggregateBy": "State",
          "OrderBy": "Key",
          "Order": "asc"
        }
      ],
      "From": 0,
      "Size": 0

    }

    this.dataService.commonPost(apiUrl, ipBody).subscribe(
      (res: any) => {

        this.isLoaded = false;
        this.getResponse = res;


        this.totalStateCount = res.Aggregations.length;

        this.state = res.Aggregations;

        this.state.sort((a, b) => (a.Key > b.Key) ? 1 : ((b.Key > a.Key) ? -1 : 0));
        this.countOfState = res.Documents;

        let stateValue = [];
        for (let d = 0; d < this.state.length; d++) {
          stateValue.push(this.state[d].Key);
        }
        this.getOnLoad(stateValue);
      }
    );
  }

  getCountyAndCount() {
    let apiUrl = environment.apiurl;
    let ipBody = {
      "SearchType": "WpdProductCounts",
      "SearchFieldValues": [
        {
          "Query": "WellActiveProducingCount: >0",
          "NotEqualOperator": false,
          "Value": 0
        },
        {
          "Field": "Country",
          "NotEqualOperator": false,
          "Value": "UNITED STATES OF AMERICA"
        }
      ],
      "IncludeFields": ["Country", "State", "StateCode", "StateCounty"],
      "From": 0,
      "Size": 5000
    }
    this.isLoaded = true;

    this.dataService.commonPost(apiUrl, ipBody).subscribe(
      (res: any) => {
        this.isLoaded = false;
        this.county = res.Documents;
        this.county.sort((a, b) => (a.StateCounty > b.StateCounty) ? 1 : ((b.StateCounty > a.StateCounty) ? -1 : 0));
        this.dropdownList = this.county;
        this.getCountyList = this.county;
        this.coutyCount = this.county.length
      });
  }

  getOnLoad(states: any) {
    let apiUrl = environment.apiurl;
    let ipBody = {
      "SearchType": "WpdProductCounts",
      "SearchFieldValues": [
        {
          "Field": "State",
          "Values": states,
          "NotEqualOperator": false
        }
      ],
      "Aggregations": [
        {
          "AggregateBy": "State",
          "PropertyName": "WellCount",
          "AggregationTypes": [
            "Sum"
          ]
        }
      ],
      "From": 0,
      "Size": 10000
    }
    this.isLoaded = true;

    this.dataService.commonPost(apiUrl, ipBody).subscribe(
      (res: any) => {
        this.isLoaded = false;
        this.getResponse = res.Documents;

        this.WellCount = 0;
        this.WellActiveProducingCount = 0;
        this.SlantSideTrackCount = 0;
        this.SlantDirectionalCount = 0;
        this.SlantVerticalCount = 0;
        this.SlantHorizontalCount = 0;
        this.WellProductionCount = 0;
        this.unkowncount = 0;
        for (let i = 0; i < this.getResponse.length; i++) {

          this.WellCount += this.getResponse[i].WellCount;

          this.WellProductionCount += this.getResponse[i].WellProductionCount;

          this.WellActiveProducingCount += this.getResponse[i].WellActiveProducingCount;

          this.SlantSideTrackCount += this.getResponse[i].SlantSideTrackCount;

          this.SlantDirectionalCount += this.getResponse[i].SlantDirectionalCount;

          this.SlantVerticalCount += this.getResponse[i].SlantVerticalCount;

          this.SlantHorizontalCount += this.getResponse[i].SlantHorizontalCount;

        }
        this.unkowncount = Number(this.WellCount - this.SlantVerticalCount - this.SlantSideTrackCount - this.SlantDirectionalCount - this.SlantHorizontalCount);

      });
  }

  onOpenModal() {
    this.tref.show();
  }
  public getHighlight(index: any): any {

    let passQuery = [];
    for (let oe = 0; oe < this.getstatesToQuery.length; oe++) {
      passQuery = this.getstatesToQuery[oe];
      switch (index) {
        case passQuery: return "bold"

      }
    }

  }

  calculateCounts(states: any[], counties: any[]) {

    var states = this.selectedState;
    var counties = this.selectedCounties;

    if (states.length > 0 && counties.length > 0) {
      this.getCountsForListOfStatesAndCounties(states, counties)
    }
    else if (states.length > 0) {
      this.getCountsForListOfStates(states)
    }
    else if (counties.length > 0) {
      this.getCountsForListOfCounties(counties, states);
    }
    else {
      return
    }
  }

  getCountsForListOfStatesAndCounties(states: any[], counties: any[]) {

    var states = this.selectedState;

    var counties = this.selectedCounties
    let statesFromSelectedCounties: any[] = [];
    let statesToQuery = [];
    let statesToQuery1 = [];
    let countiesToQUery = [];
    let countiesToQUery1 = [];
    for (let county of counties) {
      statesFromSelectedCounties.push(county["State"]);
      countiesToQUery.push(county["StateCounty"].substring(5));
      countiesToQUery1.push(county["StateCounty"]);
      this.getSameCountList = countiesToQUery1
    }
    statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
    statesToQuery = states.filter((el: any) => !statesFromSelectedCounties.includes(el));
    this.getSameStatefromparent = statesFromSelectedCounties
    this.getcountiesToQUery = countiesToQUery
    statesToQuery1 = statesFromSelectedCounties;
    let stateCounts: any = [];
    let countyCounts: any = [];


    if (statesToQuery.length > 0) {
      this.collectCount = statesFromSelectedCounties;
      this.getstatesToQuery = statesToQuery;
      for (let ox = 0; ox < statesToQuery.length; ox++) {
        this.getHighlight(statesToQuery[ox])
      }
      stateCounts = this.getCountsForListOfStates(statesToQuery);

    }


    if (countiesToQUery.length > 0 && statesToQuery1) {
      this.collectCount = statesFromSelectedCounties;
      this.getstatesToQuery = statesToQuery;
      countyCounts = this.getCountsForListOfCounties(countiesToQUery, statesToQuery1);
      countyCounts = 0;

    }
    this.WellCount = this.WellProductionCount + countyCounts.checkCountWell;
    this.WellProductionCount = this.WellProductionCount + countyCounts.prodCount;
    this.WellActiveProducingCount = this.WellActiveProducingCount + countyCounts.activeprodCount;
    this.SlantSideTrackCount = this.SlantSideTrackCount + countyCounts.sidetrackCount;
    this.SlantDirectionalCount = this.SlantDirectionalCount + countyCounts.slanDirectionCount;
    this.SlantVerticalCount = this.SlantVerticalCount + countyCounts.slanVerCount;
    this.SlantHorizontalCount = this.SlantHorizontalCount + countyCounts.slanHorCount;
    this.unkowncount = this.unkowncount + countyCounts.unknown;
  }

  getCountsForListOfStates(statesToQuery: any[] = []) {

    this.isLoaded = true;
    let apiUrl = environment.apiurl;
    let ipBody = {
      "SearchType": "WpdProductCounts",
      "SearchFieldValues": [
        {
          "Field": "State",
          "Values": statesToQuery,
          "NotEqualOperator": false
        }
      ],
      "Aggregations": [
        {
          "AggregateBy": "State",
          "PropertyName": "WellCount",
          "AggregationTypes": [
            "Sum"
          ]
        }
      ],
      "From": 0,
      "Size": 10000
    }
    // statesToQuery = [];

    this.dataService.commonPost(apiUrl, ipBody).subscribe(
      (res: any) => {
        /* this.countyChangeFormat */
        this.isLoaded = false;
        this.getResponse = res;
        this.countOfState = res.Documents;
        this.coutyCounts = 0;
        this.selectedCountyListCount = 0;
        this.coutyCount = 0;
        this.aggreationList = res.Aggregations;
        this.selectedStateCount = 0;
        this.stateWellCount = 0;
        this.stateWellProducionCount = 0;
        this.stateSlantSideTrackCount = 0;
        this.stateSlantDirectionalCount = 0;
        this.stateSlantVerticalCount = 0;
        this.stateWellActiveProducingCount = 0;
        this.stateSlantHorizontalCount = 0;

        for (let p = 0; p < this.aggreationList.length; p++) {
          this.coutyCounts += this.aggreationList[p].Value;
        }
        if (statesToQuery.length > 0 && this.selectedCounties.length == 0) {
          this.selectedCountyListCount = this.coutyCounts;
        }
        else if (this.selectedState.length == 0 && this.selectedCounties.length > 0) {
          this.selectedCountyListCount = this.getcountiesToQUery.length;
        }
        else {
          this.selectedCountyListCount = this.coutyCounts + this.getcountiesToQUery.length;

        }

        this.selectedStateCount = this.getstatesToQuery.length + this.collectCount.length;

        for (let j = 0; j < this.selectedState.length; j++) {

          for (let i = 0; i < this.countOfState.length; i++) {
            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].WellCount) {
              this.stateWellCount += this.countOfState[i].WellCount
              this.WellCount += this.countOfState[i].WellCount;
            }

            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].WellProductionCount) {
              this.stateWellProducionCount += this.countOfState[i].WellProductionCount;
              this.WellProductionCount += this.countOfState[i].WellProductionCount;
            }
            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].WellActiveProducingCount) {
              this.stateWellActiveProducingCount += this.countOfState[i].WellActiveProducingCount;
              this.WellActiveProducingCount += this.countOfState[i].WellActiveProducingCount;
            }
            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].SlantSideTrackCount) {
              this.stateSlantSideTrackCount += this.countOfState[i].SlantSideTrackCount;
              this.SlantSideTrackCount += this.countOfState[i].SlantSideTrackCount;
            }
            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].SlantDirectionalCount) {
              this.stateSlantDirectionalCount += this.countOfState[i].SlantDirectionalCount;
              this.SlantDirectionalCount += this.countOfState[i].SlantDirectionalCount;
            }
            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].SlantVerticalCount) {
              this.stateSlantVerticalCount += this.countOfState[i].SlantVerticalCount;
              this.SlantVerticalCount += this.countOfState[i].SlantVerticalCount;
            }
            if (this.countOfState[i].State == this.selectedState[j] && this.countOfState[i].SlantHorizontalCount) {
              this.stateSlantHorizontalCount += this.countOfState[i].SlantHorizontalCount;
              this.SlantHorizontalCount += this.countOfState[i].SlantHorizontalCount;
            }


          }
          this.stateUnknownCount = this.stateWellCount - this.stateSlantVerticalCount - this.stateSlantSideTrackCount - this.stateSlantDirectionalCount - this.stateSlantHorizontalCount
          this.unkowncount = Number(this.WellCount - this.SlantVerticalCount - this.SlantSideTrackCount - this.SlantDirectionalCount - this.SlantHorizontalCount);
        }
        this.countOfState = { 'check1': this.WellCount }
      });
    return this.countOfState

  }

  getCountsForListOfCounties(countiesToQUery: any, statesToQuery1: any) {

    if (this.selectedCounties.length > 0) {
      this.higlightCounty = {
        'font-weight': 'bold'
      };
    }
    this.isLoaded = true;
    this.getCounty = [];
    for (let h = 0; h < this.selectedCounties.length; h++) {
      this.getCounty.push(this.selectedCounties[h].StateCounty);
    }

    let ipBody = {}
    let apiUrl = environment.apiurl;
    if (this.selectedCounties.length >= 1 && this.selectedState.length == 0) {
      ipBody = {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
          {
            "Field": "StateCounty",
            "NotEqualOperator": false,
            "Values": this.getCounty
          }
        ],
        "Aggregations": [
          {
            "AggregateBy": "Country",
            "OrderBy": "Value",
            "Order": "asc",
            "PropertyName": "WellCount",
            "AggregationTypes": [
              "Sum"
            ]
          },
          {
            "AggregateBy": "Country",
            "OrderBy": "Value",
            "Order": "asc",
            "PropertyName": "WellActiveProducingCount",
            "AggregationTypes": [
              "Sum"
            ]
          }
        ],
        "From": 0,
        "Size": 5000
      }
    } else {
      ipBody = {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
          {
            "Field": "County",
            "NotEqualOperator": false,
            "Value": null,
            "Values": countiesToQUery
          },
          {
            "Field": "State",
            "Values": statesToQuery1,
            "NotEqualOperator": false
          }
        ],
        "Aggregations": [
          {
            "AggregateBy": "State",
            "PropertyName": "WellCount",
            "AggregationTypes": [
              "Sum"
            ]
          }
        ],
        "From": 0,
        "Size": 10000
      }
    }
    this.dataService.commonPost(apiUrl, ipBody).subscribe(
      (res: any) => {
        this.isLoaded = false;
        this.aggreationstatevalue = res.Aggregations;
        this.getStatevalue = res.Documents;
        this.WellCount = 0;
        this.WellProductionCount = 0;
        this.WellActiveProducingCount = 0;
        this.SlantSideTrackCount = 0;
        this.SlantDirectionalCount = 0;
        this.SlantVerticalCount = 0;
        this.SlantHorizontalCount = 0;
        this.selectedStateCount = 0;
        this.selectedCountyListCount = 0;
        this.coutyCount = 0;
        this.removeDup = [];
        if (countiesToQUery.length > 0 && this.getstatesToQuery.length > 0) {

          this.selectedCountyListCount = countiesToQUery.length + this.coutyCounts;

        } else {
          this.selectedCountyListCount = countiesToQUery.length;
        }

        this.selectedStateCount = statesToQuery1.length;
        for (let zex = 0; zex < countiesToQUery.length; zex++) {

          this.removeDup.push(countiesToQUery[zex].State);

          this.removeDup = [... new Set(this.removeDup)];
        }
        for (let z = 0; z < this.selectedCounties.length; z++) {
          for (let s = 0; s < this.getStatevalue.length; s++) {

            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {

              this.WellCount += this.getStatevalue[s].WellCount;

            }
            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {
              this.WellProductionCount += this.getStatevalue[s].WellProductionCount;
            }
            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {
              this.WellActiveProducingCount += this.getStatevalue[s].WellActiveProducingCount;
            }
            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {
              this.SlantSideTrackCount += this.getStatevalue[s].SlantSideTrackCount;
            }
            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {
              this.SlantDirectionalCount += this.getStatevalue[s].SlantDirectionalCount;
            }
            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {
              this.SlantVerticalCount += this.getStatevalue[s].SlantVerticalCount;
            }
            if (this.selectedCounties[z].StateCounty == this.getStatevalue[s].StateCounty) {
              this.SlantHorizontalCount += this.getStatevalue[s].SlantHorizontalCount;
            }
          }


        }
        this.unkowncount = Number(this.WellCount - this.SlantVerticalCount - this.SlantSideTrackCount - this.SlantDirectionalCount - this.SlantHorizontalCount);
        this.aggreationstatevalue = { 'checkCountWell': this.WellCount };
        this.aggreationstatevalue = { 'prodCount': this.WellProductionCount };
        this.aggreationstatevalue = { 'activeprodCount': this.WellActiveProducingCount };
        this.aggreationstatevalue = { 'sidetrackCount': this.SlantSideTrackCount };
        this.aggreationstatevalue = { 'slanDirectionCount': this.SlantDirectionalCount };
        this.aggreationstatevalue = { 'slanVerCount': this.SlantVerticalCount };
        this.aggreationstatevalue = { 'slanHorCount': this.SlantHorizontalCount };
        this.aggreationstatevalue = { 'unknown': this.unkowncount };


      });
    return this.aggreationstatevalue

  }

  logout() {

    this.oktaAuth.signOut();
    this.router.navigateByUrl('/');
  }
}


