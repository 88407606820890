import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { OktaAuthGuard, OktaAuthModule, OktaAuthService, OktaCallbackComponent, OKTA_CONFIG, OktaLoginRedirectComponent } from '@okta/okta-angular';
import { OktaAuthOptions } from '@okta/okta-auth-js';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgCascadingDropdownLibModule } from 'ng-cascading-dropdown-lib';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataService } from './data.service';
import { HomeComponent } from './home/home.component';
import { ProtectedComponent } from './protected/protected.component';
import { NgbAccordionModule, NgbActiveModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokenInterceptor } from './token.interceptor';
import { ExportComponent } from './export/export.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { MinMaxDirective } from 'src/min-max.directive';

const oktaConfig: OktaAuthOptions = {
  clientId: environment.clientId,
  issuer: environment.issuer,
  redirectUri: environment.redirectUri,
  scopes: environment.scopes,
  tokenManager: {
    storage: 'localStorage',
    autoRenew: true,
    secure: true
  },
  pkce: true
};
/* const oktaConfig: OktaAuthOptions = {
  issuer: 'https://trial-5215025.okta.com/oauth2/default',
  clientId: '0oa1lj8wy3utuyIft697',
  redirectUri: 'http://localhost:4200/callback',
  pkce: true,

}; */

export function onAuthRequired(oktaAuth: OktaAuthService, injector: Injector) {

}
const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [OktaAuthGuard],
    pathMatch: 'full'
  },

  {
    path: 'callback',
    component: OktaCallbackComponent
  },
  {
    path: 'login',
    component: OktaLoginRedirectComponent
  },
]
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProtectedComponent,
    ExportComponent, MinMaxDirective

  ],

  imports: [
    BrowserModule, UiSwitchModule, RouterModule.forRoot(appRoutes), AccordionModule.forRoot(),
    BrowserAnimationsModule, BsDatepickerModule.forRoot(),
    AppRoutingModule, NgxSpinnerModule, OktaAuthModule, NgSelectModule, FormsModule, HttpClientModule, NgCascadingDropdownLibModule, CommonModule, AngularMultiSelectModule, NgMultiSelectDropDownModule.forRoot(), NgbModule, ModalModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: OKTA_CONFIG, useValue: oktaConfig },
    DataService, Title, NgbActiveModal,
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
