import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    "(window:click)": "onClick()"
  }
})

export class AppComponent {
  name = 'World';
  title = 'angular-Pro';
  isAuthenticated = false;
  userName: any;
  user: any = '';
  showDpcontent: Boolean = false;
  issuer = environment.issuer;


  constructor(public oktaAuth: OktaAuthService, public router: Router, public titleService: Title,
    private spinner: NgxSpinnerService) {
    // subscribe to authentication state changes

    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );

    this.oktaAuth.$authenticationState.subscribe({
      next:
        authenticated => {
          if (authenticated) {
            this.oktaAuth.getUser()
              .then(it => {
                this.user = it.email;
              }
              )
          }
        }
    })

  }
  public setTitle(setTitle: string) {
    this.titleService.setTitle(setTitle);
  }
  async ngOnInit(): Promise<void> {

    // get authentication state for immediate use
    this.titleService.setTitle(environment.setTitle)
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();

  }

  login() {
    this.oktaAuth.signInWithRedirect({
      originalUri: '/login'
    });
  }

  async logout() {
    // Terminates the session with Okta and removes current tokens.
    await this.oktaAuth.signOut();
    this.router.navigateByUrl('/');
  }


  showDP($event: any) {
    $event.stopPropagation();
    this.showDpcontent = !this.showDpcontent;
  }

  onClick() {
    this.showDpcontent = false;
  }


}
