import { Injector, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaAuthService, OktaCallbackComponent } from '@okta/okta-angular';
import { HomeComponent } from './home/home.component';

export function onAuthRequired(oktaAuth: OktaAuthService, injector: Injector): void {
  /*  const router = injector.get(Router);
   router.navigate(['/login']); */
}


@NgModule({
  imports: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
