<app-export #tref [stateFromParent]="selectedState" [countyFromParent]="selectedCounties"
    [sameStateSelect]="getSameCountList" [getsameState]="getSameStatefromparent"
    [getgetstatesToQuery]="getstatesToQuery"></app-export>

<div class="d-flex hide-scroll " style="min-width: 100%;height: 100vh;">
    <div class="col-md-1 searchCol" style="border: 1px  solid #DCDCDC;">
        <div class="">
            <div class="favIcons">
                <ul class="list-group">
                    <li class="list-group-item">
                        <button class="btn btn-info btnCls">
                            <i class="fa fa-search" aria-hidden="true" style="color: #FFF"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-3 pad-align filterCol">
        <div class="">
            <h5 style="color: #FFA500;">Production Data
                <a class="pull-right clrSearch mt-1 cursorPointCls" (click)="clearArray()">Clear Search</a>
            </h5>
            <br>
            <h6>Search by State and County</h6>
            <br>

            <div class="input-dev">
                <ng-select [items]="state" [multiple]="true" bindLabel="Key" bindValue="Key" appendTo="body"
                    placeholder="Select State" [(ngModel)]="selectedState" [closeOnSelect]="false"
                    [clearSearchOnAdd]="true" (change)="onSelect_state($event)" (clear)="clearStates()">

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" id="mySelect" selected>

                        <div class="ng-value" *ngFor="let item of items.slice(0, 10);let i =index;">
                            <span class="ng-value-label" [ngClass]="{
                                'higlightState': (selectedState.length > 0 && selectedCounties.length ==0)}"
                                [ngStyle]="{fontWeight:getHighlight(item.Key)}">{{item.Key}}</span>
                            <span class="ng-value-icon right" (click)="clear(item);clearSelectdCountyValue(item)"
                                aria-hidden="true">×</span>

                        </div>

                        <div class="ng-value" *ngIf="items.length > 10">
                            <span class="ng-value-label">{{items.length - 10}} more...</span>
                        </div>
                    </ng-template>

                </ng-select>

                <br>

                <ng-select name="StateCounty" [items]="dropdownList" [multiple]="true" bindLabel="StateCounty"
                    appendTo="body" placeholder="Select County" [(ngModel)]="selectedCounties" [closeOnSelect]="false"
                    [ngModelOptions]="{standalone: true}" [clearSearchOnAdd]="true" (change)="onCounty_select($event);">

                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear" id="mySelect1" selected>
                        <div class="ng-value" *ngFor="let item of items.slice(0, 10);let i =index;">
                            <span class="ng-value-label" [ngStyle]="higlightCounty">{{item.StateCounty}}</span>
                            <span class="ng-value-icon right" (click)="clear(item);" aria-hidden="true">×</span>
                        </div>

                        <div class="ng-value" *ngIf="items.length > 10">
                            <span class="ng-value-label">{{items.length - 10}} more...</span>
                        </div>
                    </ng-template>

                </ng-select>

            </div>

        </div>
    </div>
    <div class="countCards">

        <div class="card-sec">

            <div class="card">
                <div id="cover-spin" *ngIf="isLoaded"></div>
                <div class="card-header">
                    <b style="color: #FFA500;">Active Selection Counts</b>
                    <button type="button" class="btn btn-primary font-size22 pull-right" (click)="onOpenModal()"
                        [disabled]="!(selectedState.length > 0 || selectedCounties.length > 0)">Export</button>

                </div>

                <h6 class="m-3">Region</h6>
                <div class="container-fluid px-6">
                    <div class="row gx-4">
                        <div class="col">
                            <div class="p-3 border bg-light shadow">
                                <div class="">
                                    <div class="text-center">
                                        <h5 class="card-title">{{(selectedState.length > 0 &&
                                            selectedCounties.length > 0)? selectedStateCount: selectedCounties.length >
                                            0 ?
                                            removeDup.length
                                            :
                                            (selectedState.length > 0 ) ?
                                            selectedState.length
                                            : totalStateCount |
                                            number: '1.'}}
                                        </h5>
                                        <p>State Count </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">
                                <div class="">
                                    <div class="text-center">
                                        <h5 class="card-title">{{(selectedState.length > 0 &&
                                            selectedCounties.length > 0)? selectedCountyListCount:
                                            selectedCounties.length > 0 ?
                                            selectedCounties.length
                                            :
                                            (selectedState.length > 0 ) ?
                                            selectedCountyListCount
                                            : coutyCount |
                                            number: '1.'}}

                                        </h5>

                                        <p>County Count</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 class="m-3">Well & Production</h6>
                <div class="container-fluid">
                    <div class="row row-cols-3 row-cols-lg-9 g-2 g-lg-3">
                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">
                                        {{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? WellCount:
                                        (selectedState.length > 0 ) ?
                                        stateWellCount
                                        : WellCount)
                                        | number:'1.0':'en-US'}}
                                    </h5>

                                    <p>Well Count</p>

                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">
                                        {{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? WellProductionCount:
                                        (selectedState.length > 0 ) ?
                                        stateWellProducionCount
                                        : WellProductionCount)
                                        | number:'1.0':'en-US'}}
                                    </h5>
                                    <p>With Production</p>

                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">
                                <div class="text-center">
                                    <h5 class="card-title">{{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? WellActiveProducingCount:
                                        (selectedState.length > 0 ) ?
                                        stateWellActiveProducingCount
                                        : WellActiveProducingCount)
                                        | number:'1.0':'en-US'}}</h5>
                                    <p>Actively Producing</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <h6 class="m-3">Profile</h6>

                <div class="container-fluid" style="margin-bottom: 2em;">
                    <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">

                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">{{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? unkowncount:
                                        (selectedState.length > 0 ) ?
                                        stateUnknownCount
                                        : unkowncount)| number:'1.0':'en-US' }}</h5>
                                    <p>Unknown</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">{{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? SlantSideTrackCount:
                                        (selectedState.length > 0 ) ?
                                        stateSlantSideTrackCount
                                        : SlantSideTrackCount)
                                        | number:'1.0':'en-US'}}</h5>
                                    <p>Sidetrack </p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">{{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? SlantDirectionalCount:
                                        (selectedState.length > 0 ) ?
                                        stateSlantDirectionalCount
                                        : SlantDirectionalCount)
                                        | number:'1.0':'en-US'}}</h5>
                                    <p>Directional</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">{{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? SlantVerticalCount:
                                        (selectedState.length > 0 ) ?
                                        stateSlantVerticalCount
                                        : SlantVerticalCount)
                                        | number:'1.0':'en-US'}}</h5>
                                    <p>Vertical</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="p-3 border bg-light shadow">

                                <div class="text-center">
                                    <h5 class="card-title">{{((selectedState.length > 0 &&
                                        selectedCounties.length > 0)? SlantHorizontalCount:
                                        (selectedState.length > 0 ) ?
                                        stateSlantHorizontalCount
                                        : SlantHorizontalCount)
                                        | number:'1.0':'en-US'}}</h5>
                                    <p>Horizontal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>