import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalConstants } from './app.constants';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {

    constructor(public oktaAuth: OktaAuthService, public router: Router,
        private spinner: NgxSpinnerService) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(httpRequest).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    console.log(err);

                    if (err.status === 401) {
                        // redirect user to the logout page
                        console.log("401");
                        // this.logout();
                        this.spinner.hide();
                        let targetElement: any = document.getElementById('cover-spin');
                        targetElement.style.display = "none";
                        Swal.fire({
                            icon: 'info',
                            html: '<h5>Your session has expired.</h5>' +
                                'Click OK to return to the Login page.',
                            confirmButtonText: 'OK',
                            iconColor: '#11375f'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.logout();
                            }
                        })
                        // Swal.fire({
                        //     icon: 'error',
                        //     html: '<h5>Sorry for the inconvenience.</h5>' +
                        //         'The application has encountered an unknown error.',
                        //     confirmButtonText: 'OK',
                        //     iconColor: '#f2545b'
                        // })
                    } else {
                        this.spinner.hide();
                        let targetElement: any = document.getElementById('cover-spin');
                        targetElement.style.display = "none";
                        Swal.fire({
                            icon: 'error',
                            html: '<h5>' + GlobalConstants.inconvenienceMsg + '</h5>' +
                                GlobalConstants.sysEncouterMsg,
                            confirmButtonText: 'OK',
                            iconColor: '#f2545b'
                        })
                    }
                }
                return throwError(err);
            })
        )
    }

    async logout() {
        // Terminates the session with Okta and removes current tokens.
        await this.oktaAuth.signOut();
        this.router.navigateByUrl('/');
    }
}