
export const environment = {
  production: false,
  hostUrl: 'https://saga-dev.tgs.com/',
  clientId: '0oa1424xe07q2FEcM0h8',
  redirectUri: 'https://saga-dev.tgs.com/callback',
  baseUrl: 'https://login-dev.tgsnopec.com',
  issuer: 'https://login-dev.tgsnopec.com/oauth2/auspth6n0r1gXj1tV0h7',
  scopes: ['openid', 'email', 'profile', 'TgsGlobalDevelopment.DataLake', 'TgsGlobalDevelopment.ProductDelivery'],
  setTitle: 'Saga-DEV',
  apiurl: 'https://api-workshop.tgs.com/Dev/DataLake/Search',
  apiKey: 'FFBFCE9E-58F8-42CF-98C8-E0B5D945FE74',
  exportUrl: 'https://api-workshop.tgs.com/Dev/ProductDelivery/PackageProducts'
};


