<div class="navbar" *ngIf="isAuthenticated">

  <a class="mtop">
    <img src="assets\TGS_logo_white_XL.png" height="30">
  </a>
  <div class="dropdown mtop">
    <!-- <button class="dropbtn" (click)="showDP($event)" #toggleButton>
      <i class="fa fa-user" aria-hidden="true"></i>
    </button> -->
    <img class="cursorPointCls" src="assets\TGSUserIcon.svg" width="40" height="30" (click)="showDP($event)"
      #toggleButton>
    <div class="dropdown-content" *ngIf="showDpcontent">
      <a class="bCls">{{user}}</a>
      <a class="cursorPointCls" (click)="logout()">Log Out</a>
    </div>
  </div>

</div>

<router-outlet></router-outlet>