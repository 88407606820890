import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OktaAuthService } from '@okta/okta-angular';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DataService } from '../data.service';
import { GlobalConstants } from '../app.constants';
import { ThisReceiver } from '@angular/compiler';
// import { NgbdModalContent } from '../modal-content';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {
  bsValue = new Date();
  isDisableWeek: any;
  isShowScheduleSelection: boolean = false
  northSessionTypeVal = "T";
  // inputPlaceeholderText: number = 1;
  isCheck297: boolean = false;
  public maxValue: number = 52;
  public minValue: number = 1;
  dayname: any;
  week_day = [
    { id: 1, value: "Su", isSelected: false, dayName: "Sunday" },
    { id: 2, value: "M", isSelected: false, dayName: "Monday" },
    { id: 3, value: "Tu", isSelected: false, dayName: "Tuesday" },
    { id: 4, value: "W", isSelected: false, dayName: "Wednesday" },
    { id: 5, value: "Th", isSelected: false, dayName: "Thursday" },
    { id: 6, value: "F", isSelected: false, dayName: "Friday" },
    { id: 7, value: "Sa", isSelected: false, dayName: "Sunday" }
  ];
  northReference = [
    { value: 'T', id: "True", label: "True", checked: true },
    { value: 'G', id: "Grid", label: "Grid", checked: false }

  ];
  dataList = [
    { code: 1, name: "Week(s)" },
    // { code: 2, name: "Month(s)" },
  ];

  InputValue: any = 1;

  @ViewChild(BsDatepickerDirective, { static: false })
  datepicker!: BsDatepickerDirective;
  contentForFullExport = "Includes all available data";
  contentForDate = "Includes only new or changed data after this date";
  contentForFileFormat = "Select one or more file formats. Click arrows for additional options.";
  contentForSchedule = "Enter a date and frequency to receive recurring export deliveries."
  @Input() stateFromParent: any[] = [];
  @Input() countyFromParent: any[] = [];
  @Input() sameStateSelect: any[] = [];
  @Input() getsameState: any[] = [];
  @Input() getgetstatesToQuery: any[] = [];
  @ViewChild("lgModalChild") lgModalChild: any;
  isCheckedInc: boolean = false;
  isDateSelFlag: boolean = false;
  setBold: any;
  checkedAll = false;
  checkedHeader: boolean = false;
  styleHeader: any;
  isShow297 = false;
  isShow298 = false;
  isShowCSV = false;
  isNanSt = true;
  isNanEnd = true;
  isNanRepeat = true;
  isNanoccur = true;
  isHeaderChecked = false;
  exportName: any;
  exportDesc: any;
  maxCharsName = 60;
  maxChars = 255;
  date_regex = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;
  isFullExportOption: boolean = false;
  options: Array<any> = [
    { value: 1, id: "297", checked: false, isAccordionOpen: false, label: "297 Well Export" },
    { value: 2, id: "298", checked: false, isAccordionOpen: false, label: "298 Production Export" },
    { value: 3, id: "CSV", checked: false, isAccordionOpen: false, label: "CSV" },
  ]
  ischecked: boolean = false;
  checkAllTrades: boolean = false;
  newOptions = [
    { value: 4, id: "fullExport", label: "Full Export", checked: true, disable: false },
    { value: 5, id: "incrementalExport", label: "Incremental Export", checked: false, disable: false }

  ];
  csvSelected: Array<any> = [

    // { keyName: 'Headers', label: 'Headers', selected: false, disable: false },
    { keyName: 'LeaseGroup', label: 'Lease/Group ', selected: false, disable: false },
    { keyName: 'LevelWellAndAllocated', label: 'Well/Allocated Production', selected: false, disable: false },
    { keyName: 'LevelWellFormation', label: 'Formation Production', selected: false, disable: false },
    { keyName: 'LevelLease', label: 'Lease/Group Production', selected: false, disable: false },
    { keyName: 'Completions', label: 'Completions', selected: false, disable: false },
    { keyName: 'Casing', label: 'Casing', selected: false, disable: false },
    { keyName: 'Perfs', label: 'Well Perforations', selected: false, disable: false },
    { keyName: 'Tests', label: 'Well Tests', selected: false, disable: false },
    // { keyName: 'FormationTops', label: 'Formation Tops', selected: false, disable: false },
    { keyName: 'Injection', label: 'Injection ', selected: false, disable: false },
    { keyName: 'GetDisposition', label: 'Gas Disposition ', selected: false, disable: false }
  ];
  selected297 = [
    { id: 1, label: 'Comma Delimited (97C)', selected: false, disable: false, groupName: 'WellHeader-297c', fileFormat: '297c' },
    { id: 2, label: 'Fixed Field (97F)', selected: false, disable: false, groupName: 'WellHeader-297f', fileFormat: '297f' },

  ];
  selected298 = [
    { id: 1, label: 'Comma Delimited (98C)', selected: false, disable: false, groupName: 'WellProduction-298c', fileFormat: '298c' },
    { id: 2, label: 'Fixed Field (98F)', selected: false, disable: false, groupName: 'WellProduction-298f', fileFormat: '298f' },

  ];
  showText: boolean = false;
  isDatePickerDisabled: boolean = true;
  includesChangesSinceDate: any = "";
  selectedDate: any;
  selectedStartDate: any;
  selectedEndDate: any;
  isPayNow: any;
  show297Option: boolean = false;
  show298Option: boolean = false;
  isShowType: boolean = false;
  user: any;
  fullUserName: any;
  selectedOption: any;
  disabled: boolean = true;
  enableDate: boolean = true
  getprodType: any;
  getFileFormat: any;
  selection298: any;
  showModal: boolean = false;
  isLoaded: boolean = false;
  toggleSelected = true;
  isTypetoggleSelected = true
  getCSV: any[] = [];
  showScheduledate: boolean = false;
  isShowSchedule: boolean = true;
  isOptselected: any;
  checkDisabled: boolean = true;
  isFirstFullCheck: boolean = true;
  checked: any;
  isdisabled: boolean = true;
  isDisableType: boolean = true
  isweekSelectBg: any;
  minStartDate = new Date();
  minEndDate: any;
  maxEndDate: any;
  isinputText: boolean = true
  colortheme: any;
  checkDate: boolean = true;
  exportType: string = "Full";
  constructor(private dataService: DataService, public oktaAuth: OktaAuthService, private modalService: NgbModal, private cdref: ChangeDetectorRef) {

  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();

  }
  ngOnInit(): void {

    this.oktaAuth.getUser()
      .then(it => {
        this.user = it.email;
        this.fullUserName = it.name;
      })
      for (let e = 0; e < this.options.length; e++) {
      this.options[e].id = {
        'font-weight': 'normal'
      };
    }
  }
  getUTCMidnight(dateObj: any) {
    let date = `${dateObj.getUTCDate()}`.padStart(2, "0");
    let month = `${dateObj.getUTCMonth() + 1}`.padStart(2, "0");
    let year = dateObj.getUTCFullYear();
    return new Date(`${year}-${month}-${date}T00:00:00Z`);
  }

  getCurrentUTCStartDate() {
    return this.getUTCMidnight(this.selectedStartDate);
  }
  getCurrentUTCEnddate() {
    return this.getUTCMidnight(this.selectedEndDate);
  }
  export() {
    let ipBody = {};
    let exportUrl = environment.exportUrl;
    this.isLoaded = true;
    let countyVal = [];
    let reverseCountyVal = []
    for (let d = 0; d < this.countyFromParent.length; d++) {
      countyVal.push(this.countyFromParent[d].StateCounty);
      let str = countyVal[d]
      reverseCountyVal.push(str.split(' - ').reverse().join(' - '));
      this.sameStateSelect.push(str.split(' - ').reverse().join(' - '))

    }

    let Identifiers: any[] = [];
    let csvStatsAndCounties: any = {};
    if (this.stateFromParent.length > 0 && reverseCountyVal.length == 0) {

      Identifiers = [
        JSON.stringify({ "States": this.stateFromParent, "IncludeChangesSinceDate": this.includesChangesSinceDate })
      ];
      csvStatsAndCounties = {
        States: this.stateFromParent
      }
    } else if (reverseCountyVal.length > 0 && this.stateFromParent.length == 0) {

      Identifiers = [
        JSON.stringify({ "Counties": reverseCountyVal, "IncludeChangesSinceDate": this.includesChangesSinceDate })
      ];
      csvStatsAndCounties = {
        Counties: reverseCountyVal
      }
    } else if (reverseCountyVal.length > 0 && this.getgetstatesToQuery.length > 0) {

      Identifiers = [JSON.stringify({ "States": this.getgetstatesToQuery, "Counties": reverseCountyVal, "IncludeChangesSinceDate": this.includesChangesSinceDate })];
      csvStatsAndCounties = { "States": this.getgetstatesToQuery, "Counties": reverseCountyVal };
    } else if (this.sameStateSelect.length > 0 && this.getgetstatesToQuery.length == 0) {
      Identifiers = [
        JSON.stringify({ "Counties": reverseCountyVal, "IncludeChangesSinceDate": this.includesChangesSinceDate })
      ];
      csvStatsAndCounties = { "Counties": reverseCountyVal };
    }

    let selected297Options = [];
    let selected298Options = [];
    let selectedCSVOptions: any[] = [];
    let allProducts = [];
    let opDate = new Date().toLocaleString('en-US', {
      timeZone: 'CST'
    });
    let identifiers297 = [];
    let identifiers297Obj = {};
    identifiers297Obj = JSON.parse(Identifiers[0]);
    Object.assign(identifiers297Obj, { NorthReference: this.northSessionTypeVal });
    identifiers297 = [JSON.stringify(identifiers297Obj)];
    for (let i = 0; i < this.selected297.length; i++) {
      if (this.selected297[i].selected) {
        selected297Options.push({
          "ProductType": this.selected297[i].groupName,
          "FileFormat": this.selected297[i].fileFormat,
          "IdentifierType": "Json",
          "Identifiers": identifiers297
        });
      }
    }

    for (let j = 0; j < this.selected298.length; j++) {
      if (this.selected298[j].selected) {
        selected298Options.push({
          "ProductType": this.selected298[j].groupName,
          "FileFormat": this.selected298[j].fileFormat,
          "IdentifierType": "Json",
          "Identifiers": Identifiers
        });
      }
    }

    let objectSam: any = {};
    objectSam = this.csvSelected.reduce(
      (obj, item) => Object.assign(obj, { [item.keyName]: item.selected }), {});
    delete objectSam['Headers'];
    let identifiersBody;
    identifiersBody = JSON.stringify({
      ...csvStatsAndCounties,
      ...objectSam,
      "IncludeChangesSinceDate": this.includesChangesSinceDate
    })
    const selectedCSVValue = this.csvSelected.filter((value) => value.selected).length;
    if (selectedCSVValue > 0) {
      selectedCSVOptions = [{
        "ProductType": "CSV",
        "FileFormat": "csv",
        "IdentifierType": "Json",
        "Identifiers": [identifiersBody]
      }];
    }
    if (this.isHeaderChecked) {
      selectedCSVOptions = [{
        "ProductType": "CSV",
        "FileFormat": "csv",
        "IdentifierType": "Json",
        "Identifiers": [identifiersBody]
      }];
    }

    allProducts = [...selected297Options, ...selected298Options, ...selectedCSVOptions];

    if (this.isShowScheduleSelection) {
      let repWeeks = [];
      for (let w = 0; w < this.week_day.length; w++) {
        if (this.week_day[w].isSelected) {
          repWeeks.push(this.week_day[w].dayName);
        }
      }

      ipBody =
      {
        "CustomerName": this.fullUserName,
        "UserEmail": this.user,
        "PackageDescription": "TGS Well Data Download " + opDate.replace(',', '') + " CST",
        "Schedule": {
          "ExportType": this.exportType,
          "StartDateUtc": this.getCurrentUTCStartDate(),
          "DeliveryDays": repWeeks,
          "EndDateUtc": this.getCurrentUTCEnddate(),
          "WeeklyRecurrence": parseInt(this.InputValue)
        },
        "Deliveries": [
          {
            "DeliveryFormatType": "Single",
            "DeliveryType": "SignedUrl",
            "PackagingSystem": "WDP_SAGA",
            "PackagingSystemDescription": GlobalConstants.packagingSystemDescription,
            "DeliveryName": this.exportName.trim(),
            "DeliveryDescription": this.exportDesc ? this.exportDesc.trim() : null,
            "Products": allProducts
          }
        ],
        "NotificationEmails": [
          this.user
        ]
      }
    } else {

      ipBody =
      {
        "CustomerName": this.fullUserName,
        "UserEmail": this.user,
        "PackageDescription": "TGS Well Data Download " + opDate.replace(',', '') + " CST",

        "Deliveries": [
          {
            "DeliveryFormatType": "Single",
            "DeliveryType": "SignedUrl",
            "PackagingSystem": "WDP_SAGA",
            "PackagingSystemDescription": GlobalConstants.packagingSystemDescription,
            "DeliveryName": this.exportName.trim(),
            "DeliveryDescription": this.exportDesc ? this.exportDesc.trim() : null,
            "Products": allProducts
          }
        ],
        "NotificationEmails": [
          this.user
        ]
      }
    }

    this.dataService.commonPost(exportUrl, ipBody).subscribe(
      (resp: any) => {

        if (resp.Success == true) {
          this.swalAlert();
          this.refresh();
          this.toggleSelected = false
          this.isLoaded = false;
        } else {
          this.isLoaded = false
          this.swalErrorAlert(resp.FailureReason);
        }
      });
  }

  swalAlert() {
    Swal.fire({
      icon: 'success',
      html: '<h5>Your Export has been submitted.</h5>' +
        'A link to access this file will be sent to your email shortly. Please contact R360CustomerSupport@tgs.com if you have any issues.',
      confirmButtonText: 'OK'

    })
  }
  swalErrorAlert(msg: any) {
    Swal.fire({
      icon: 'error',
      title: '<h5>' + GlobalConstants.inconvenienceMsg + '</h5>',
      html: msg != "" ? msg : GlobalConstants.sysEncouterMsg,
      confirmButtonText: 'OK',
      iconColor: '#f2545b'
    })
  }
  show() {
    this.lgModalChild.show();
  }
  singleCheckbox(event: any) {

    if (event.target.checked == true) {
      this.ischecked = true
    }

    if (this.ischecked) {
      event.target.checked = true
    }
  }
  toggleCheckboxAll(event: any) {
    const checked = event.target.checked;
    if (event.target.checked) {
      this.checkedAll = true
    } else {
      this.checkedAll = false
    }
    this.csvSelected.forEach(item => item.selected = checked);
    if (checked) {
      this.isShowCSV = true
      this.checkedHeader = true;
      this.checkedAll = true
      this.options[2].id = {
        'font-weight': 'bold'
      };
      this.styleHeader = {
        'color': 'darkGray'
      }
    } else {
      this.isShowCSV = false
      this.checkedAll = false
      this.checkedHeader = false;
      this.options[2].id = {
        'font-weight': 'normal'
      };
      this.styleHeader = {
        'color': '#222'
      }
    }
  }


  headerCheck(event: any) {
    const checked = event.target.checked;
    this.checkedAll = false;

    if (event.target.checked) {
      this.isShowCSV = true
      this.isHeaderChecked = true;
      this.options[2].id = {
        'font-weight': 'bold'
      };
    } else {
      this.isShowCSV = false
      this.isHeaderChecked = false;
      this.options[2].id = {
        'font-weight': 'normal'
      };
    }

  }

  is297Selected(item: any, index: number) {

    this.selected297[index].selected = !this.selected297[index].selected;
    const setBold = this.selected297.filter((value) => value.selected).length;
    if (setBold > 0) {
      this.isCheck297 = true;
      this.isShow297 = true;
      this.options[0].id = {
        'font-weight': 'bold'
      };
    } else {
      this.isShow297 = false;
      this.isCheck297 = false;
      this.options[0].id = {
        'font-weight': 'normal'
      };
    }

  }

  is298Selected(item: any, index: number) {

    this.selected298[index].selected = !this.selected298[index].selected;
    const setBold = this.selected298.filter((value) => value.selected).length;
    if (setBold > 0) {
      this.isShow298 = true;
      this.options[1].id = {
        'font-weight': 'bold'
      };
    } else {
      this.isShow298 = false;
      this.options[1].id = {
        'font-weight': 'normal'
      };
    }

  }

  isCSVSelected(val: any, index: number) {
    this.csvSelected[index].selected = !this.csvSelected[index].selected;
    const setBold = this.csvSelected.filter((value) => value.selected).length;
    if (setBold > 0) {
      this.isShowCSV = true;
      this.options[2].id = {
        'font-weight': 'bold'
      };
    } else {
      this.isShowCSV = false;
      this.options[2].id = {
        'font-weight': 'normal'
      };
    }
    if (setBold == this.csvSelected.length) {
      this.toggleSelected = true;
      this.checkedAll = true;
    } else if (setBold == 0) {
      this.checkedHeader = false;
      this.styleHeader = {
        'color': '#222'
      }

    }
    else {
      this.checkedAll = false;
      this.checkedHeader = true;
      this.styleHeader = {
        'color': 'darkGray'
      }
    }

  }

  refresh() {
    this.ngOnInit();

  }

  isTypeChange(option: any) {

    if (option.value == 4) {
      this.exportType = "Full";
      this.isdisabled = true;
      this.isDisableType = true
      this.isCheckedInc = false;
      this.selectedDate = ''
      this.includesChangesSinceDate = "";
      this.checkDisabled = true;
      this.checkDate = true;
      this.isFirstFullCheck = true;
    } else {
      this.isdisabled = false;
      this.isDisableType = false;
      this.isFirstFullCheck = true;
      this.isCheckedInc = true;
      this.checkDisabled = false;
      this.checkDate = false;

      if (this.isShowScheduleSelection) {
        this.isCheckedInc = false;
      }
      if (this.isShowSchedule) {
        this.exportType = "FullIncremental";
      } else {
        this.exportType = "Incremental";
      }
    }
  }
  isFirstRunSelected(event: any) {
    if (event.target.checked) {
      this.exportType = "FullIncremental";
      this.includesChangesSinceDate = '';
      this.isCheckedInc = false;
      this.isFirstFullCheck = true;
    } else {
      this.isFirstFullCheck = false;
      this.isCheckedInc = true;
      this.exportType = "Incremental";
    }
  }

  isInvalidDate(event: any) {
    let test = event.target.value;
    if (test == 'Invalid date') {
      this.isCheckedInc = true;
      this.isDateSelFlag = false;
    }
  }

  onDateChange(value: Date): void {
    if (value) {
      this.isCheckedInc = false;
      this.disabled = false;
      this.includesChangesSinceDate = this.convertDate(value);
    }
    if (!value) {
      this.isdisabled = false;

    }
    else {
      this.isCheckedInc = false;
      this.disabled = true;
    }

  }

  convertDate(date: any) {
    var yyyy = date ? date.getFullYear().toString() : '';
    var mm = date ? (date.getMonth() + 1).toString() : '';
    var dd = date ? date.getDate().toString() : '';
    var mmChars = mm.split('');
    var ddChars = dd.split('');
    return date ? yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]) : "";
  }

  resetOnDate() {
    this.selectedDate = '';
    this.disabled = false;
    this.isCheckedInc = true
  }
  resetOnStartDate() {
    this.selectedStartDate = '';
    this.selectedEndDate = '';
  }
  resetOnEndDate() {
    this.selectedEndDate = '';
  }

  disableOther(day: any, index: number) {
    this.week_day.forEach(val => {
      if (val.id == day.id) {
        val.isSelected = !val.isSelected;

      }
      else {
        val.isSelected = false;
      }
    });
    const isSetDay = this.week_day.filter((value) => value.isSelected).length;
    if (isSetDay > 0) {
      this.isNanRepeat = true;

    }
    else {
      this.isNanRepeat = false;
    }
  }

  resetAll() {
    this.week_day.forEach((initial: any) => {
      initial.isSelected = false;
    }
    )
  }
  onChange(e: any) {
    this.isShowScheduleSelection = e;
    this.isFullExportOption = e;
    this.showScheduledate = e;

    if (e == false) {
      this.isNanSt = true;
      this.isNanEnd = true;
      this.isNanRepeat = true;
      this.isNanoccur = true;
      this.isdisabled = false;

      if (e == false && !this.isShowScheduleSelection && this.checkDate) {

        this.includesChangesSinceDate = '';
        this.selectedEndDate = '';
        this.isCheckedInc = false;

      }
      else {
        this.isCheckedInc = true;
        this.includesChangesSinceDate = '';
      }

      if (this.checkDate) {
        this.exportType = "Full";
      } else {
        this.exportType = "Incremental";
      }
    } else {
      if (this.checkDate) {
        this.exportType = "Full";
      } else {
        this.exportType = "FullIncremental";
      }
      this.selectedStartDate = new Date();
      this.InputValue = 1;
      this.isNanSt = false;
      this.isNanEnd = false;
      this.isNanRepeat = false;
      this.resetAll();
      this.includesChangesSinceDate = '';
      this.isdisabled = true;
      this.isCheckedInc = false;
      this.selectedEndDate = ''
    }
  }

  onOptionsSelected(event: any) {
    const value = event.target.value;
    this.InputValue = 1;
    this.isOptselected = value;
  }
  resetRepeat() {
    this.InputValue = '';
    this.isNanoccur = false;
  }
  isrepeat(ev: any) {
    const value = ev.target.value;
    this.InputValue = value;
    if (value) {
      this.isNanoccur = true;
    } else {
      this.isNanoccur = false;
    }

  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  isInvalidStartDate(event: any) {
    let test = event.target.value;

    if (test == 'Invalid date') {
      this.isNanSt = false;

    }
  }
  onStartDateChange(value: Date): void {

    if (this.selectedEndDate < value) {
      this.isNanSt = true;
      let sDate = new Date(value);
      this.minEndDate = new Date(sDate.setDate(sDate.getDate() + 1));
      this.maxEndDate = new Date(sDate.setDate(sDate.getDate() + 363));
    } else {
      this.isNanSt = false;
    }

  }
  isNorthRefCheck(check: any) {
    this.northSessionTypeVal = check.value;
    this.northReference.forEach(val => {
      if (val.id == check.id) {
        val.checked = !val.checked;
      }
      else {
        val.checked = false;
      }
    });

  }

  isInvalidEndDate(event: any) {
    let test = event.target.value;
    if (test == 'Invalid date') {
      this.isNanEnd = false;
    }
  }
  onEndDateChange(value: Date): void {
    if (value) {
      this.isNanEnd = true;
    } else {
      this.isNanEnd = false;
    }

  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.toggleSelected = false;
    this.ngOnInit();
  }

}
