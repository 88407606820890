export class GlobalConstants {

    public static sessionInterVal: number = 600000;
    public static setHrs: number = 23;
    public static setMins: number = 59;
    public static setSecs: number = 59;
    // public static sessionInterVal: number = 1000;
    public static setTimeOut: number = 3000;
    public static packagingSystemDescription: string = "TGS Well Data";
    public static inconvenienceMsg: string = "Sorry for the inconvenience.";
    public static sysEncouterMsg: string = "The application has encountered an unknown error.";
}